import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface SpacingSectionProps {
    componentData: {
        spacingValue: number;
    };
}

const SpacingSection: React.FC<SpacingSectionProps> = ({ componentData }) => {
    const { spacingValue } = componentData;
    const [responsiveSpacing, setResponsiveSpacing] = useState(spacingValue);

    const updateResponsiveSpacing = () => {
        if (window.innerWidth < 640) {
            setResponsiveSpacing(spacingValue / 2); // Mobil ekranlar
        } else if (window.innerWidth < 1024) {
            setResponsiveSpacing(spacingValue * 0.75); // Tablet ekranlar
        } else {
            setResponsiveSpacing(spacingValue); // Büyük ekranlar
        }
    };

    useEffect(() => {
        updateResponsiveSpacing(); // İlk değer
        window.addEventListener('resize', updateResponsiveSpacing); // Resize dinleyici
        return () => window.removeEventListener('resize', updateResponsiveSpacing); // Cleanup
    }, [spacingValue]);

    return (
        <div
            className="w-full"
            style={{
                marginTop: `${responsiveSpacing}px`,
                marginBottom: `${responsiveSpacing}px`
            }}
        >
            <motion.div
                className="w-full h-[50px] bg-transparent"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, ease: 'easeInOut' }}
            />
        </div>
    );
};

export default SpacingSection;
