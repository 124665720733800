import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, A11y, Autoplay } from "swiper/modules";

interface MiniCardSliderSectionProps {
  componentData: {
    cards: {
      buttonText: string;
      buttonUrl: string;
      text: string;
      logoMedia: string; // Logo veya görsel medya
      backgroundMedia?: string; // Arka plan medya
      videoMedia?: string; // Video opsiyonel olarak eklendi
    }[];
  };
}

const MiniCardSliderSection: React.FC<MiniCardSliderSectionProps> = ({
  componentData,
}) => {
  const { cards } = componentData;
  const slideRefs = useRef<(HTMLElement | null)[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const swiperRef = useRef<any>(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log(
              `Kart görünüyor: ${
                cards[slideRefs.current.indexOf(entry.target as HTMLElement)].text
              }`
            );
          }
        });
      },
      { threshold: 0.5 }
    );

    slideRefs.current.forEach((ref) => {
      if (ref) observer.current?.observe(ref);
    });

    return () => {
      slideRefs.current.forEach((ref) => {
        if (ref) observer.current?.unobserve(ref);
      });
    };
  }, [cards]);

  const isYoutubeUrl = (url: string) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return youtubeRegex.test(url);
  };

  const isVideoUrl = (url: string) => {
    const videoRegex = /\.(mp4|webm|ogg)$/;
    return videoRegex.test(url);
  };

  const handleClick = (url: string) => {
    if (isYoutubeUrl(url) || isVideoUrl(url)) {
      setMediaUrl(url);
      setIsPopupOpen(true);
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  };

  return (
    <div className="relative w-full flex items-center justify-center bg-white">
      {cards.length >= 4 ? ( // 4 veya daha fazla kart varsa Swiper kullan
        <Swiper
          modules={[Pagination, Navigation, A11y, Autoplay]}
          navigation
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          speed={1500}
          loop={true}
          centeredSlides={false}
          breakpoints={{
            640: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 2, spaceBetween: 15 },
            1024: { slidesPerView: 3, spaceBetween: 30 },
            1280: { slidesPerView: 4, spaceBetween: 40 },
          }}
          onMouseEnter={() => swiperRef.current?.swiper?.autoplay.stop()}
          onMouseLeave={() => swiperRef.current?.swiper?.autoplay.start()}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index}>
              <div
                ref={(el) => (slideRefs.current[index] = el)}
                className="w-[85%] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl mx-auto"
              >
                {card.backgroundMedia ? (
                  <img
                    src={card.backgroundMedia}
                    alt="Görsel medya"
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                  />
                ) : (
                  <p>Medya bulunamadı</p>
                )}
                <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent">
                  <p className="text-base font-semibold">{card.text}</p>
                  {card.buttonText && card.buttonUrl && (
                    <button
                      onClick={() => handleClick(card.buttonUrl)}
                      className="absolute flex items-center justify-center px-6 py-2 text-white bg-white bg-opacity-20 border border-white rounded-full opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300"
                      style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {card.buttonText}
                    </button>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : ( // Daha az kart varsa statik görünüm kullan
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-4">
          {cards.map((card, index) => (
            <div
              key={index}
              className="w-[85%] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl mx-auto"
            >
              {card.backgroundMedia ? (
                <img
                  src={card.backgroundMedia}
                  alt="Görsel medya"
                  className="w-full h-full object-cover absolute top-0 left-0 z-0"
                />
              ) : (
                <p>Medya bulunamadı</p>
              )}
              <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent">
                <p className="text-base font-semibold">{card.text}</p>
                {card.buttonText && card.buttonUrl && (
                  <button
                    onClick={() => handleClick(card.buttonUrl)}
                    className="absolute flex items-center justify-center px-6 py-2 text-white bg-white bg-opacity-20 border border-white rounded-full opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {card.buttonText}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
          <div className="relative w-full h-full">
            <button
              onClick={() => setIsPopupOpen(false)}
              className="absolute top-4 right-4 text-red-600 text-5xl z-50 hover:text-red-800 transition-all bg-transparent"
            >
              ✕
            </button>
            {isYoutubeUrl(mediaUrl) ? (
              <iframe
              src={`${mediaUrl.replace("watch?v=", "embed/")}?autoplay=1`}
                title="Media Content"
                className="absolute inset-0 w-full h-full"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <video
                src={mediaUrl}
                controls
                autoPlay
                className="absolute inset-0 w-full h-full"
                onContextMenu={(e) => e.preventDefault()} // Sağ tıklamayı devre dışı bırak
                controlsList="nodownload" // İndirme seçeneğini kaldır
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniCardSliderSection;
