import React, { useRef } from 'react';
import { motion } from 'framer-motion';

interface SponsorCardSectionProps {
    componentData: {
        sponsorCards: Array<{
            logo: string;
            subtitle: string;
            url: string;
        }>;
    };
}

const SponsorCardSection: React.FC<SponsorCardSectionProps> = ({ componentData }) => {
    const sliderRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: -300, // Kart genişliği kadar kaydır
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: 300, // Kart genişliği kadar kaydır
                behavior: 'smooth',
            });
        }
    };

    const isScrollable = componentData.sponsorCards.length > 6;

    return (
        <div
            className="relative w-full h-auto flex items-center justify-center bg-white"
            style={{ margin: '0 auto', overflow: 'hidden', maxWidth: '90%' }}
        >
            {/* Sol Ok Butonu */}
            {isScrollable && (
                <div
                    className="absolute left-4 text-red-800 hover:text-red transition-all cursor-pointer z-10"
                    onClick={scrollLeft}
                >
                    <span className="text-5xl">&#10094;</span>
                </div>
            )}
            {/* Kaydırma Alanı */}
            <motion.div
                ref={sliderRef}
                className={`flex ${isScrollable ? 'overflow-x-auto' : 'justify-center'
                    } scrollbar-hide p-4 w-full scroll-smooth`}
                style={{
                    gap: '20px',
                    scrollSnapType: 'x mandatory',
                    scrollPadding: '10px',
                }}
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, ease: 'easeOut' }}
            >
                {componentData.sponsorCards.map((card, index) => (
                    <motion.a
                        key={index}
                        href={card.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="min-w-[200px] max-w-[250px] h-[220px] bg-white rounded-2xl border shadow-md flex flex-col items-center justify-start p-4 snap-center"
                        whileHover={{
                            scale: 1.05,
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                        }}
                        transition={{ duration: 0.3 }}
                    >
                        {/* Logo Alanı */}
                        <div
                            className="w-24 h-24 mb-4 flex items-center justify-center rounded-md overflow-hidden"
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                        >
                            <img
                                src={card.logo}
                                alt="Sponsor Logo"
                                className="object-contain w-full h-full"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }}
                            />
                        </div>
                        {/* Alt Başlık */}
                        <p
                            className="text-center text-gray-800 text-m font-semibold line-clamp-2 overflow-hidden"
                            style={{
                                fontFamily: 'Rajdhani, sans-serif',
                                maxWidth: '90%',
                            }}
                        >
                            {card.subtitle}
                        </p>
                    </motion.a>
                ))}
            </motion.div>
            {/* Sağ Ok Butonu */}
            {isScrollable && (
                <div
                    className="absolute right-4 text-red-800 hover:text-red transition-all cursor-pointer z-10"
                    onClick={scrollRight}
                >
                    <span className="text-5xl">&#10095;</span>
                </div>
            )}
        </div>
    );
};

export default SponsorCardSection;
