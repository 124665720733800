import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Logo seçici bileşen
import SponsorCardSection from '../../UI/sections/SponsorCardSection'; // SponsorCardSection önizleme bileşeni
import { S3File } from '../../services/types'; // S3File tipi
interface SponsorCardFormProps {
    onSave: (data: any) => void;
}
const SponsorCardForm: React.FC<SponsorCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // Tasarım ID'si
    const [sponsorCards, setSponsorCards] = useState([
        { logo: null as S3File | null, subtitle: '', url: '' }
    ]);
    const [isLogoSelectorOpen, setIsLogoSelectorOpen] = useState<number | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal durumu
    const MAX_SUBTITLE_LENGTH = 50;
    const handleAddSponsorCard = () => {
        setSponsorCards([...sponsorCards, { logo: null, subtitle: '', url: '' }]);
    };
    const handleCardChange = (index: number, field: string, value: string) => {
        const updatedCards = sponsorCards.map((card, i) =>
            i === index ? { ...card, [field]: value } : card
        );
        setSponsorCards(updatedCards);
    };
    const handleLogoSelect = (index: number, file: S3File) => {
        const updatedCards = sponsorCards.map((card, i) =>
            i === index ? { ...card, logo: file } : card
        );
        setSponsorCards(updatedCards);
        setIsLogoSelectorOpen(null);
    };
    const handleRemoveCard = (index: number) => {
        setSponsorCards(sponsorCards.filter((_, i) => i !== index));
    };
    const handleSave = async () => {
        const componentData = {
            designId: id,
            componentName: 'SponsorCard',
            componentData: {
                sponsorCards: sponsorCards.map(card => ({
                    ...card,
                    logo: card.logo?.url || '' // Logo URL'sini alın
                })),
            },
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den gelen yanıtı ilet
        } catch (error) {
            console.error('Veri kaydedilirken hata oluştu:', error);
        }
    };
    const handlePreview = () => {
        setIsPreviewOpen(true); // Önizleme modalını aç
    };
    return (
        <div>
            {sponsorCards.map((card, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">Sponsor Kart {index + 1}</h3>
                        <button
                            onClick={() => handleRemoveCard(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded"
                        >
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Logo</label>
                        <button
                            onClick={() => setIsLogoSelectorOpen(index)}
                            className="relative w-full p-2 border border-gray-300 rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-gray-700">{card.logo ? card.logo.key : 'Logo Seç'}</span>
                        </button>
                        {card.logo && (
                            <div className="mt-2">
                                <img
                                    src={card.logo.url}
                                    alt={card.logo.key}
                                    className="w-16 h-16 object-contain rounded-lg"
                                />
                            </div>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Alt Başlık</label>
                        <input
                            type="text"
                            value={card.subtitle}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_SUBTITLE_LENGTH) handleCardChange(index, 'subtitle', e.target.value);
                            }}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md"
                            placeholder="Alt başlık"
                        />
                        <p className="text-sm text-gray-500">{card.subtitle.length}/{MAX_SUBTITLE_LENGTH} karakter</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">URL</label>
                        <input
                            type="text"
                            value={card.url}
                            onChange={(e) => handleCardChange(index, 'url', e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md"
                            placeholder="Yönlendirme URL'si"
                        />
                    </div>
                    <MediaSelector
                        isOpen={isLogoSelectorOpen === index}
                        onSelect={(file: S3File) => handleLogoSelect(index, file)}
                        onClose={() => setIsLogoSelectorOpen(null)}
                    />
                </div>
            ))}
            <button onClick={handleAddSponsorCard} className="px-4 py-2 bg-blue-500 text-white rounded-md">
                Sponsor Kart Ekle
            </button>
            <div className="flex justify-end space-x-4 mt-4">
                <button onClick={handlePreview} className="px-4 py-2 bg-gray-500 text-white rounded-md">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2 bg-green-500 text-white rounded-md">
                    Kaydet
                </button>
            </div>
            {isPreviewOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6">
                        <SponsorCardSection
                            componentData={{
                                sponsorCards: sponsorCards.map(card => ({
                                    ...card,
                                    logo: card.logo?.url || '',
                                })),
                            }}
                        />
                        <button onClick={() => setIsPreviewOpen(false)} className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md">
                            Kapat
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default SponsorCardForm;