import React, { useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper as SwiperClass } from "swiper";

interface TextMiniSliderCardSectionProps {
    componentData: {
        cards: {
            buttonText: string;
            buttonUrl: string;
            media: string;
            text: string;
        }[];
    };
}

const TextMiniSliderCardSection: React.FC<TextMiniSliderCardSectionProps> = ({
    componentData,
}) => {
    const { cards } = componentData;
    const swiperRef = useRef<SwiperRef | null>(null);

    // Pop-Up State
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [mediaUrl, setMediaUrl] = useState("");

    const renderMedia = (mediaUrl: string) => {
        const fileExtension = mediaUrl.split(".").pop()?.toLowerCase();
        const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
        const videoTypes = ["mp4", "webm", "ogg"];

        if (imageTypes.includes(fileExtension || "")) {
            return (
                <img
                    src={mediaUrl}
                    alt="Card Media"
                    className="w-full h-full object-cover"
                />
            );
        } else if (videoTypes.includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover"
                    autoPlay
                    loop
                    muted
                >
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else {
            return (
                <p className="text-gray-500 text-center">
                    Desteklenmeyen medya formatı
                </p>
            );
        }
    };

    const isYoutubeUrl = (url: string) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+/;
        return youtubeRegex.test(url);
    };
    
    const isVideoUrl = (url: string) => {
        const videoRegex = /\.(mp4|webm|ogg)(\?.*)?$/i; // Video uzantılarını kesinleştir
        return videoRegex.test(url);
    };
    
   
    const handleCardClick = (buttonUrl: string, cardText: string) => {
        if (isYoutubeUrl(buttonUrl)) {
            setMediaUrl(buttonUrl.replace("watch?v=", "embed/"));
            setIsPopupOpen(true);
        } else if (isVideoUrl(buttonUrl)) {
            setMediaUrl(buttonUrl);
            setIsPopupOpen(true);
        } else  {
            console.log("Dış bağlantıya yönlendiriliyor:", buttonUrl);
            window.open(buttonUrl, "_blank", "noopener noreferrer");
        } 
    };
    const handleHoverLog = (cardText: string) => {
        console.log("Üzerine gelinen kart metni:", cardText);
    };

    return (
        <div className="relative w-full flex items-center justify-center bg-white">
            {cards.length >= 3 ? (
                <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                    breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 10 },
                        768: { slidesPerView: 2, spaceBetween: 15 },
                        1024: { slidesPerView: 3, spaceBetween: 20 },
                    }}
                    loop={cards.length > 3}
                    speed={800}
                    onSwiper={(swiper: SwiperClass) => {
                        setTimeout(() => {
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }, 0);
                    }}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="w-[92%] md:w-[72%] lg:w-[68%] h-[320px] bg-white rounded-3xl mx-auto overflow-hidden group"
                                onMouseEnter={() => handleHoverLog(card.text)}
                            >
                                <div className="relative h-[65%]">
                                    {renderMedia(card.media)}
                                    {card.buttonUrl && (
                                        <button
                                            onClick={() =>
                                                handleCardClick(card.buttonUrl, card.text)
                                            }
                                            className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black/50"
                                        >
                                            <span
                                                className="px-8 py-3 text-lg font-medium text-white bg-transparent border-2 border-white rounded-full hover:bg-white hover:text-black transition-all duration-300"
                                            >
                                                {card.buttonText}
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <div className="relative h-[35%] p-4 bg-white rounded-b-3xl">
                                    <p className="text-gray-800 text-base font-medium leading-relaxed text-left max-w-full truncate overflow-hidden">
                                        {card.text}
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-4">
                    {cards.map((card, index) => (
                        <div
                            key={index}
                            className="w-[92%] md:w-[72%] lg:w-[68%] h-[320px] bg-white rounded-3xl mx-auto overflow-hidden group"
                            onMouseEnter={() => handleHoverLog(card.text)}
                        >
                            <div className="relative h-[65%]">
                                {renderMedia(card.media)}
                                {card.buttonUrl && (
                                    <button
                                        onClick={() =>
                                            handleCardClick(card.buttonUrl, card.text)
                                        }
                                        className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black/50"
                                    >
                                        <span
                                            className="px-8 py-3 text-lg font-medium text-white bg-transparent border-2 border-white rounded-full hover:bg-white hover:text-black transition-all duration-300"
                                        >
                                            {card.buttonText}
                                        </span>
                                    </button>
                                )}
                            </div>
                            <div className="relative h-[35%] p-4 bg-white rounded-b-3xl">
                                <p className="text-gray-800 text-base font-medium leading-relaxed text-left max-w-full truncate overflow-hidden">
                                    {card.text}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Pop-Up */}
            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                    <div className="relative w-full h-full">
                        <button
                            onClick={() => setIsPopupOpen(false)}
                            className="absolute top-4 right-4 text-red-600 text-5xl z-50 hover:text-red-800 transition-all bg-transparent"
                        >
                            ✕
                        </button>
                        {isYoutubeUrl(mediaUrl) ? (
                            <iframe
                            src={`${mediaUrl.replace("watch?v=", "embed/")}?autoplay=1`}
                                title="YouTube Video"
                                className="absolute inset-0 w-full h-full"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        ) : (
                            <video
                                src={mediaUrl}
                                controls
                                autoPlay
                                className="absolute inset-0 w-full h-full"
                                onContextMenu={(e) => e.preventDefault()} // Sağ tıklamayı devre dışı bırak
                                controlsList="nodownload" // İndirme seçeneğini kaldır
                            />
                        )}
                    </div>
                </div>
            )}

            {/* Navigation Buttons */}
            {cards.length > 3 && (
                <>
                    <div className="swiper-button-prev text-black rounded-full w-12 h-12 flex items-center justify-center cursor-pointer absolute top-1/2 left-4 transform -translate-y-1/2 z-10"></div>
                    <div className="swiper-button-next text-black  rounded-full  w-12 h-12 flex items-center justify-center cursor-pointer absolute top-1/2 right-4 transform -translate-y-1/2 z-10"></div>
                </>
            )}
        </div>
    );
};

export default TextMiniSliderCardSection;
