import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

interface TwinTopTitleHeroCardSectionProps {
  componentData: {
    rightCard: {
      media: string;
      coverImage?: string;
      title: string;
      subtitle: string;
      buttonText: string;
      buttonUrl: string;
    };
    leftCard: {
      media: string;
      coverImage?: string;
      title: string;
      subtitle: string;
      buttonText: string;
      buttonUrl: string;
    };
  };
}

const TwinTopTitleHeroCardSection: React.FC<TwinTopTitleHeroCardSectionProps> = ({
  componentData,
}) => {
  const { rightCard, leftCard } = componentData;

  const leftRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);
  const [leftInView, setLeftInView] = useState(false);
  const [rightInView, setRightInView] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMediaUrl, setPopupMediaUrl] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === leftRef.current) {
            setLeftInView(entry.isIntersecting);
          }
          if (entry.target === rightRef.current) {
            setRightInView(entry.isIntersecting);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (leftRef.current) observer.observe(leftRef.current);
    if (rightRef.current) observer.observe(rightRef.current);

    return () => {
      if (leftRef.current) observer.unobserve(leftRef.current);
      if (rightRef.current) observer.unobserve(rightRef.current);
    };
  }, []);

  const isYoutubeUrl = (url: string) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  const renderMedia = (mediaUrl: string, coverImage?: string) => {
    if (mediaUrl.endsWith(".mp4") || mediaUrl.endsWith(".webm") || mediaUrl.endsWith(".ogg")) {
      return (
        <div className="relative w-full h-full">
          {coverImage ? (
            <img src={coverImage} alt="Cover" className="w-full h-full object-cover rounded-3xl" />
          ) : (
            <video className="w-full h-full object-cover rounded-3xl" autoPlay muted loop>
              <source src={mediaUrl} type="video/mp4" />
            </video>
          )}
        </div>
      );
    } else {
      return <img src={mediaUrl} alt="Card Media" className="w-full h-full object-cover rounded-3xl" />;
    }
  };

  const handleButtonClick = (url: string) => {
    if (isYoutubeUrl(url) || url.endsWith(".mp4") || url.endsWith(".webm") || url.endsWith(".ogg")) {
      setPopupMediaUrl(url); // Popup'ta açılacak medya URL'sini ayarla
      setIsPopupOpen(true);
    } else {
      window.open(url, "_blank"); // Diğer linkler yeni sekmede açılır
    }
  };

  const renderButton = (buttonUrl: string, buttonText: string) => {
    if (!buttonUrl) return null;

    return (
      <motion.div
        className="absolute bottom-12 right-0 z-30"
        style={{ width: "200px", height: "65px" }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.3 }}
      >
        <button
          onClick={() => handleButtonClick(buttonUrl)}
          className="relative w-[150px] px-6 py-3 border-2 border-white rounded-full text-white font-bold text-m transition-colors duration-300 block overflow-hidden"
          style={{ background: "rgba(255, 255, 255, 0)" }}
        >
          <span className="relative z-10">{buttonText}</span>
          <div className="absolute inset-0 bg-white opacity-0 hover:opacity-20 transition-opacity duration-300 rounded-full"></div>
        </button>
      </motion.div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row justify-between p-5 bg-white mb-5 w-[85vw] h-[70vh] mx-auto mt-16 space-y-5 md:space-y-0 md:space-x-7 max-w-none">
      {/* Popup */}
      {isPopupOpen && popupMediaUrl && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
          <div className="relative w-full h-full">
            <button
              onClick={() => setIsPopupOpen(false)}
              className="absolute top-4 right-4 text-red-600 text-5xl z-50 hover:text-red-800 transition-all bg-transparent"
            >
              ✕
            </button>
            {isYoutubeUrl(popupMediaUrl) ? (
              <iframe
                src={popupMediaUrl.replace("watch?v=", "embed/") + "?autoplay=1"}
                title="Media Content"
                className="absolute inset-0 w-full h-full"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <video
                src={popupMediaUrl}
                controls
                autoPlay
                className="absolute inset-0 w-full h-full"
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
              />
            )}
          </div>
        </div>
      )}

      {/* Left Card */}
      <div
        ref={leftRef}
        className={`flex-1 bg-[#DFE2E6] overflow-hidden relative transition-all duration-1000 ease-in-out transform rounded-3xl ${leftInView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
          } hover:shadow-lg group`}
      >
        <div className="absolute bottom-0 left-0 w-full p-5 h-[35%] bg-gradient-to-t from-black to-transparent opacity-80 z-10 rounded-b-none">
          <div className="absolute bottom-24 left-5 text-white font-sans z-20 space-y-2">
            <h3 className="text-[20px] sm:text-[24px] md:text-[30px] font-bold mb-2 tracking-wider">{leftCard.title}</h3>
            <p className="text-[16px] font-semibold mb-5 line-clamp-2">{leftCard.subtitle}</p>
          </div>
        </div>
        {renderButton(leftCard.buttonUrl, leftCard.buttonText)}
        {renderMedia(leftCard.media, leftCard.coverImage)}
      </div>

      {/* Right Card */}
      <div
        ref={rightRef}
        className={`flex-1 bg-[#DFE2E6] overflow-hidden relative transition-all duration-1000 ease-in-out transform rounded-3xl ${rightInView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
          } hover:shadow-lg group`}
      >
        <div className="absolute bottom-0 left-0 w-full p-5 h-[35%] bg-gradient-to-t from-black to-transparent opacity-80 z-10 rounded-b-none">
          <div className="absolute bottom-24 left-5 text-white font-sans z-20 space-y-2">
            <h3 className="text-[30px] font-bold mb-2 tracking-wider">{rightCard.title}</h3>
            <p className="text-[16px] font-semibold mb-5 line-clamp-2">{rightCard.subtitle}</p>
          </div>
        </div>
        {renderButton(rightCard.buttonUrl, rightCard.buttonText)}
        {renderMedia(rightCard.media, rightCard.coverImage)}
      </div>
    </div>
  );
};

export default TwinTopTitleHeroCardSection;
