import React, { useState, useRef } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useInView } from 'react-intersection-observer';

interface LargeFlipCardSectionProps {
    componentData: {
        frontMedia: string;
        backMedia: string;
    };
    marginTop?: number; // Dinamik margin-top..
    marginBottom?: number; // Dinamik margin-bottom..
}

const LargeFlipCardSection: React.FC<LargeFlipCardSectionProps> = ({ componentData, marginTop = 0, marginBottom = 0 }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false); // Video oynatma durumu

    // Video referansı
    const videoRef = useRef<HTMLVideoElement>(null);

    // Kartın görünürlüğünü takip etmek için useInView kullanıyoruz
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.5, // Kartın %50'si görünür olduğunda tetikler
    });

    // Kartı çevirmek için kullanılan fonksiyon
    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    const handleBackgroundClick = () => {
        if (isFlipped) {
            setIsFlipped(false);
        }
    };

    // Medya dosyasının türünü belirleyen fonksiyon (resim, video, vb.)
    const renderMedia = (mediaUrl: string) => {
        const mediaExtension = mediaUrl.split('.').pop()?.toLowerCase();
        if (mediaExtension === 'mp4' || mediaExtension === 'webm' || mediaExtension === 'ogg') {
            return (
                <video
                    ref={videoRef}
                    className="object-cover w-full h-full rounded-3xl" // Köşe yuvarlatması 3XL
                    onClick={togglePlayPause} // Videoya tıklandığında oynat/durdur
                    loop // Video sonsuz döngüde oynar
                    muted // Videoyu sessiz olarak başlatır
                >
                    <source src={mediaUrl} type={`video/${mediaExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }
        return <img src={mediaUrl} alt="Media" className="object-cover w-full h-full rounded-3xl" />; // Köşe yuvarlatması 3XL
    };

    // Video oynatma/durdurma fonksiyonu
    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    // Başlangıç stili (kart görünmezken)
    const sectionStyle: React.CSSProperties = {
        opacity: 0,
        transform: "translateY(50px)", // Aşağıda başlar
        transition: "transform 1s ease, opacity 1s ease",
    };

    // Görünür olduğunda uygulanacak stil
    const visibleStyle: React.CSSProperties = {
        opacity: 1,
        transform: "translateY(0)", // Yukarı kayar
        transition: "transform 1s ease, opacity 1s ease",
    };

    return (
        <div
            className="relative"
            style={{
                marginTop: `${marginTop}px`, // Dinamik margin-top
                marginBottom: `${marginBottom}px`, // Dinamik margin-bottom
            }}
        >
            {/* Arka plan karartma efekti */}
            {isFlipped && (
                <div
                    className="fixed inset-0 z-10"
                    onClick={handleBackgroundClick}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        animation: 'fade 2s infinite alternate', // Yanıp sönme efekti
                    }}
                ></div>
            )}

            <div
                ref={ref} // Bileşenin görünürlüğünü takip eder
                className={`flex justify-center items-center space-x-8 p-8 bg-white-100 z-20 relative ${isFlipped ? 'fixed inset-0' : ''}`}
                style={{
                    ...(inView ? visibleStyle : sectionStyle), // Kart görünür olduğunda animasyon başlar
                }}
            >
                {/* Kartın ön ve arka yüzlerini çevirmek için ReactCardFlip kullanıyoruz */}
                <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                    {/* Ön kart */}
                    <div
                        className="relative w-full sm:w-[85vw] h-[50vh] sm:h-[70vh] bg-transparent shadow-2xl flex items-center justify-center z-30 rounded-3xl transition-all duration-700 ease-in-out"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderMedia(componentData.frontMedia)}
                        <button
    className="absolute bottom-4 right-4 bg-gray-200 p-2 rounded-full shadow-md transition-all bg-opacity-20 hover:bg-opacity-80 hover:bg-gray-400 hover:scale-110 hover:shadow-xl border border-gray-400"
    onClick={handleFlip}
    style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', cursor: 'pointer' }}
>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 transition-colors text-black"
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
    </svg>
</button>

                    </div>

                    {/* Arka kart */}
                    <div
                        className="relative w-full sm:w-[85vw] h-[50vh] sm:h-[70vh] bg-transparent shadow-2xl flex items-center justify-center z-30 rounded-3xl transition-all duration-700 ease-in-out"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderMedia(componentData.backMedia)}
                        <button
    className="absolute bottom-4 right-4 bg-gray-200 p-2 rounded-full shadow-md transition-all bg-opacity-20 hover:bg-opacity-80 hover:bg-gray-400 hover:scale-110 hover:shadow-xl border border-gray-400"
    onClick={handleFlip}
    style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', cursor: 'pointer' }}
>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 transition-colors text-black"
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
    </svg>
</button>

                    </div>
                </ReactCardFlip>
            </div>

            {/* Keyframes for fading animation */}
            <style>
                {`
                    @keyframes fade {
                        0% { opacity: 0.3; }
                        100% { opacity: 0.6; }
                    }
                `}
            </style>
        </div>
    );
};

export default LargeFlipCardSection;
