import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector';
import { S3File } from '../../services/types';
import TwinTopTitleHeroCardSection from '../../UI/sections/TwinTopTitleHeroCardSection';

interface TwinTopTitleHeroCardFormProps {
    onSave: (data: any) => void;
}

const TwinTopTitleHeroCardForm: React.FC<TwinTopTitleHeroCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [isRightMediaSelectorOpen, setIsRightMediaSelectorOpen] = useState(false);
    const [isLeftMediaSelectorOpen, setIsLeftMediaSelectorOpen] = useState(false);
    const [isRightCoverSelectorOpen, setIsRightCoverSelectorOpen] = useState(false);
    const [isLeftCoverSelectorOpen, setIsLeftCoverSelectorOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const [rightMedia, setRightMedia] = useState<S3File | null>(null);
    const [leftMedia, setLeftMedia] = useState<S3File | null>(null);
    const [rightCoverImage, setRightCoverImage] = useState<S3File | null>(null);
    const [leftCoverImage, setLeftCoverImage] = useState<S3File | null>(null);
    const [rightTitle, setRightTitle] = useState('');
    const [rightSubtitle, setRightSubtitle] = useState('');
    const [rightButtonText, setRightButtonText] = useState('');
    const [rightButtonUrl, setRightButtonUrl] = useState('');
    const [leftTitle, setLeftTitle] = useState('');
    const [leftSubtitle, setLeftSubtitle] = useState('');
    const [leftButtonText, setLeftButtonText] = useState('');
    const [leftButtonUrl, setLeftButtonUrl] = useState('');

    const maxTitleLength = 35;
    const maxSubtitleLength = 80;
    const maxButtonTextLength = 14;

    const isVideo = (media: S3File | null) => {
        const fileExtension = media?.key.split('.').pop()?.toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(fileExtension || '');
    };

    const handleSave = async () => {
        if (!rightMedia || !leftMedia) return;

        const componentData = {
            designId: id,
            componentName: 'Twin Top Title Hero Card',
            componentData: {
                rightCard: {
                    media: rightMedia?.url,
                    coverImage: rightCoverImage?.url || '',
                    title: rightTitle,
                    subtitle: rightSubtitle,
                    buttonText: rightButtonText,
                    buttonUrl: rightButtonUrl,
                },
                leftCard: {
                    media: leftMedia?.url,
                    coverImage: leftCoverImage?.url || '',
                    title: leftTitle,
                    subtitle: leftSubtitle,
                    buttonText: leftButtonText,
                    buttonUrl: leftButtonUrl,
                },
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleRightMediaSelect = (file: S3File) => {
        setRightMedia(file);
        if (isVideo(file)) {
            setRightButtonUrl(file.url); // Video URL'sini otomatik olarak Buton URL'ye ata
        }
        setIsRightMediaSelectorOpen(false);
    };
    
    const handleLeftMediaSelect = (file: S3File) => {
        setLeftMedia(file);
        if (isVideo(file)) {
            setLeftButtonUrl(file.url); // Video URL'sini otomatik olarak Buton URL'ye ata
        }
        setIsLeftMediaSelectorOpen(false);
    };
    

    const handleRightCoverSelect = (file: S3File) => {
        setRightCoverImage(file);
        setIsRightCoverSelectorOpen(false);
    };

    const handleLeftCoverSelect = (file: S3File) => {
        setLeftCoverImage(file);
        setIsLeftCoverSelectorOpen(false);
    };

    const handlePreviewOpen = () => {
        setIsPreviewOpen(true);
    };

    const renderMediaPreview = (media: S3File | null) => {
        if (!media) return null;

        const fileExtension = media.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 object-contain mx-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    const handleTitleChange = (setFunction: React.Dispatch<React.SetStateAction<string>>, value: string, maxLength: number) => {
        if (value.length <= maxLength) {
            setFunction(value);
        }
    };

    return (
        <div className="grid grid-cols-2 gap-4">
        {/* Sağ Kart */}
        <div className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
            <h3 className="text-[#243757] text-sm font-normal">Sağ Kart</h3>
            <label className="block text-[#243757] text-sm font-normal mt-2">Medya (Önerilen ölçüler: 1060x756 piksel)</label>
            <button
                onClick={() => setIsRightMediaSelectorOpen(true)}
                className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
            >
                <span className="text-[#091E42]">{rightMedia ? rightMedia.key : 'Medya Seç'}</span>
            </button>
            {renderMediaPreview(rightMedia)}
      {/* Video ise Kapak Görseli ve Buton URL */}
      {isVideo(rightMedia) && (
        <>
            <div className="mt-4">
                <label className="block text-[#243757] text-sm font-normal">Kapak Görseli</label>
                <button
                    onClick={() => setIsRightCoverSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                >
                    <span className="text-[#091E42]">{rightCoverImage ? rightCoverImage.key : 'Kapak Görseli Seç'}</span>
                </button>
                {renderMediaPreview(rightCoverImage)}
            </div>
            <div className="mt-4">
                <label className="block text-[#243757] text-sm font-normal">Buton URL (Video URL'si Otomatik Eklendi)</label>
                <input
                    type="text"
                    value={rightButtonUrl}
                    onChange={(e) => setRightButtonUrl(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Buton Url"
                />
            </div>
        </>
    )}
    
            <label className="block text-[#243757] text-sm font-normal mt-4">Başlık (En fazla 35 karakter)</label>
            <input
                type="text"
                value={rightTitle}
                onChange={(e) => handleTitleChange(setRightTitle, e.target.value, maxTitleLength)}
                className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                placeholder="Başlık"
            />
            <p className="text-sm text-gray-500">{rightTitle.length}/{maxTitleLength}</p>
    
            <label className="block text-[#243757] text-sm font-normal mt-4">Alt Başlık (En fazla 80 karakter)</label>
            <input
                type="text"
                value={rightSubtitle}
                onChange={(e) => handleTitleChange(setRightSubtitle, e.target.value, maxSubtitleLength)}
                className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                placeholder="Alt Başlık"
            />
            <p className="text-sm text-gray-500">{rightSubtitle.length}/{maxSubtitleLength}</p>
    
            <label className="block text-[#243757] text-sm font-normal mt-4">Buton Adı (En fazla 14 karakter)</label>
            <input
                type="text"
                value={rightButtonText}
                onChange={(e) => handleTitleChange(setRightButtonText, e.target.value, maxButtonTextLength)}
                className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                placeholder="Buton Adı"
            />
            <p className="text-sm text-gray-500">{rightButtonText.length}/{maxButtonTextLength}</p>
    
            <label className="block text-[#243757] text-sm font-normal mt-4">Buton Url</label>
            <input
                type="text"
                value={rightButtonUrl}
                onChange={(e) => setRightButtonUrl(e.target.value)}
                className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                placeholder="Buton Url"
            />
        </div>

          {/* Sol Kart */}
<div className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
    <h3 className="text-[#243757] text-sm font-normal">Sol Kart</h3>
    <label className="block text-[#243757] text-sm font-normal mt-2">Medya (Önerilen ölçüler: 1060x756 piksel)</label>
    <button
        onClick={() => setIsLeftMediaSelectorOpen(true)}
        className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
    >
        <span className="text-[#091E42]">{leftMedia ? leftMedia.key : 'Medya Seç'}</span>
    </button>
    {renderMediaPreview(leftMedia)}

    {/* Video ise Kapak Görseli ve Buton URL */}
    {isVideo(leftMedia) && (
        <>
            <div className="mt-4">
                <label className="block text-[#243757] text-sm font-normal">Kapak Görseli</label>
                <button
                    onClick={() => setIsLeftCoverSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                >
                    <span className="text-[#091E42]">{leftCoverImage ? leftCoverImage.key : 'Kapak Görseli Seç'}</span>
                </button>
                {renderMediaPreview(leftCoverImage)}
            </div>
            <div className="mt-4">
                <label className="block text-[#243757] text-sm font-normal">Buton URL (Video URL'si Otomatik Eklendi)</label>
                <input
                    type="text"
                    value={leftButtonUrl}
                    onChange={(e) => setLeftButtonUrl(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Buton Url"
                />
            </div>
        </>
    )}

    <label className="block text-[#243757] text-sm font-normal mt-4">Başlık (En fazla 35 karakter)</label>
    <input
        type="text"
        value={leftTitle}
        onChange={(e) => handleTitleChange(setLeftTitle, e.target.value, maxTitleLength)}
        className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
        placeholder="Başlık"
    />
    <p className="text-sm text-gray-500">{leftTitle.length}/{maxTitleLength}</p>

    <label className="block text-[#243757] text-sm font-normal mt-4">Alt Başlık (En fazla 80 karakter)</label>
    <input
        type="text"
        value={leftSubtitle}
        onChange={(e) => handleTitleChange(setLeftSubtitle, e.target.value, maxSubtitleLength)}
        className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
        placeholder="Alt Başlık"
    />
    <p className="text-sm text-gray-500">{leftSubtitle.length}/{maxSubtitleLength}</p>

    <label className="block text-[#243757] text-sm font-normal mt-4">Buton Adı (En fazla 14 karakter)</label>
    <input
        type="text"
        value={leftButtonText}
        onChange={(e) => handleTitleChange(setLeftButtonText, e.target.value, maxButtonTextLength)}
        className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
        placeholder="Buton Adı"
    />
    <p className="text-sm text-gray-500">{leftButtonText.length}/{maxButtonTextLength}</p>

    <label className="block text-[#243757] text-sm font-normal mt-4">Buton Url</label>
    <input
        type="text"
        value={leftButtonUrl}
        onChange={(e) => setLeftButtonUrl(e.target.value)}
        className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
        placeholder="Buton Url"
    />
</div>

           {/* Media Selectors */}
           <MediaSelector
                isOpen={isRightMediaSelectorOpen}
                onSelect={handleRightMediaSelect}
                onClose={() => setIsRightMediaSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isLeftMediaSelectorOpen}
                onSelect={handleLeftMediaSelect}
                onClose={() => setIsLeftMediaSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isRightCoverSelectorOpen}
                onSelect={handleRightCoverSelect}
                onClose={() => setIsRightCoverSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isLeftCoverSelectorOpen}
                onSelect={handleLeftCoverSelect}
                onClose={() => setIsLeftCoverSelectorOpen(false)}
            />

       {/* Sağ Alt Köşe Butonlar */}
       <div className="absolute bottom-0 right-4 flex space-x-4">
        <button
            onClick={handlePreviewOpen}
            className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow"
        >
            Önizleme
        </button>
        <button
            onClick={handleSave}
            className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight"
        >
            Ekle
        </button>
    </div>

    {/* Önizleme Modal */}
    {isPreviewOpen && (
        <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={() => setIsPreviewOpen(false)}
        >
            {/* Önizleme İçerik */}
            <div
                className="relative flex items-center justify-center"
                onClick={(e) => e.stopPropagation()} // Modal içi tıklama engellemesi
            >
                {/* Sağ Üstte Kapatma Butonu */}
                <button
                            className="absolute top-4 right-4 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 transition-all z-50"
                            onClick={() => setIsPreviewOpen(false)}
                            style={{
                                position: "fixed", // Sabit konumlandırma
                                top: "20px", // Sayfanın üstünden 20px boşluk
                                right: "20px", // Sağdan 20px boşluk
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                {/* Kart İçeriği */}
                <TwinTopTitleHeroCardSection
                    componentData={{
                        rightCard: {
                            media: rightMedia?.url || '',
                            title: rightTitle,
                            subtitle: rightSubtitle,
                            buttonText: rightButtonText,
                            buttonUrl: rightButtonUrl,
                        },
                        leftCard: {
                            media: leftMedia?.url || '',
                            title: leftTitle,
                            subtitle: leftSubtitle,
                            buttonText: leftButtonText,
                            buttonUrl: leftButtonUrl,
                        },
                    }}
                />
            </div>
        </div>
    )}
        </div>
    );
};

export default TwinTopTitleHeroCardForm;
