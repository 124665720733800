import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface AccordionSection {
    title: string;
    subtitle: string;
}

interface Accordion3DViewerSectionProps {
    componentData: {
        features: string;
        oid: string;
        accordionSections: AccordionSection[];
    };
}

const Accordion3DViewerSection: React.FC<Accordion3DViewerSectionProps> = ({ componentData }) => {
    const { features, oid, accordionSections } = componentData;
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const [iframeContent, setIframeContent] = useState<string>('');

    useEffect(() => {
        if (inView && features && oid) {
            setIframeContent(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <title>3D Viewer</title>
                <style>
                    body, html {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        background: transparent;
                    }
                    clooned-object {
                        width: 100%;
                        height: 100%;
                    }
                </style>
                <script src="https://clooned.com/wp-content/uploads/cloons/scripts/clooned.js" async></script>
            </head>
            <body>
                <clooned-object
                    features="${features}"
                    oid="${oid}"
                    width="100%"
                    height="100%"
                ></clooned-object>
            </body>
            </html>
        `);
        }
    }, [inView, features, oid]);

    const toggleAccordion = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const titleVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 80, damping: 20, duration: 2 } },
    };

    return (
        <motion.div
            ref={ref}
            className="p-5 bg-white rounded-3xl shadow-lg mb-8 flex justify-center items-center w-full md:w-[85vw] h-[50vh] md:h-[70vh] mx-auto"
            initial={{ opacity: 0, y: 100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: 'easeOut' }}
        >
            {/* Sol taraf - Accordion */}
            <div className="w-full lg:w-1/2 bg-white-50 p-6 space-y-8" style={{ marginTop: 90, marginBottom: 20 }}>
                {componentData.accordionSections.map((section: AccordionSection, index: number) => (
                    <motion.div key={index} className="pb-4" variants={titleVariants}>
                        <motion.div
                            className="flex justify-between items-center pl-16 max-w-[65%] mx-auto"
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={titleVariants}
                        >
                            <button
                                className="text-2xl text-gray-700 font-bold text-left"
                                style={{ fontFamily: 'Radhanji, sans-serif' }}
                                onClick={() => toggleAccordion(index)}
                            >
                                {section.title}
                            </button>
                            <button
                                className="ml-auto text-xl p-1 transition-transform"
                                onClick={() => toggleAccordion(index)}
                            >
                                {activeIndex === index ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                )}
                            </button>
                        </motion.div>
                        <div className="w-[55%] border-b border-gray-300 mt-2 mb-4 mx-auto"></div>
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: activeIndex === index ? 'auto' : 0, opacity: activeIndex === index ? 1 : 0 }}
                            transition={{ type: 'spring', stiffness: 80, damping: 20, duration: 2 }}
                            className="overflow-hidden"
                        >
                            <div
                                className="text-base text-gray-700 mt-2 pl-12 pt-2 max-w-[50%] mx-auto text-left break-words"
                                style={{ fontFamily: 'Radhanji, sans-serif' }}
                            >
                                {Array.isArray(section.subtitle) ? (
                                    section.subtitle.map((item: string, subIndex: number) => (
                                        <p key={subIndex} className="mb-2">{item}</p>
                                    ))
                                ) : (
                                    <p>{section.subtitle || 'İçerik yok'}</p>
                                )}
                            </div>
                        </motion.div>
                    </motion.div>
                ))}
            </div>
            {/* 3D Viewer Section */}
            <div className="w-full lg:w-1/2 h-96 lg:h-[60vh]">
                <div className="w-full h-full rounded-3xl overflow-hidden">
                    {iframeContent && (
                        <iframe
                            srcDoc={iframeContent}
                            className="w-full h-full"
                            style={{
                                border: 'none',
                                width: '100%',
                                height: '100%',
                            }}
                            title="3D Model Viewer"
                            scrolling="no"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media;"
                        />
                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default Accordion3DViewerSection;
