import React, { useState, useEffect } from 'react';
import api from '../services/apiService';
import NewMediaPopup from '../components/NewMediaPopup';
import MediaViewer from '../components/MediaViewer';
import { GrSearch } from "react-icons/gr";
import { FaTrash } from "react-icons/fa";
import { S3File } from '../services/types';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from '../components/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GalleryListPage: React.FC = () => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // Şu anki sayfa
  const itemsPerPage = 20; // Sayfa başına medya sayısı
  const navigate = useNavigate();

  const fetchFiles = async () => {
    try {
      const response = await api.get<S3File[]>(`${process.env.REACT_APP_API_URL}/s3files`);
      setFiles(response.data.reverse());
    } catch (error) {
      console.error('Veriler getirilirken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const checkAuthToken = () => {
      const token = Cookies.get('adminToken');
      if (!token) {
        navigate('/admin-login');
      }
    };

    checkAuthToken();
    fetchFiles();
  }, [navigate]);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    fetchFiles();
  };

  const handleOpenViewer = (fileUrl: string) => {
    setSelectedFileUrl(fileUrl);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setSelectedFileUrl('');
  };

  const getMediaType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension ? extension.toUpperCase() : 'Unknown';
  };

  const handleDelete = async (fileKey: string) => {
    const isConfirmed = window.confirm('Bu dosyayı silmek istediğinizden emin misiniz?');

    if (!isConfirmed) {
      return;
    }

    try {
      await api.delete(`${process.env.REACT_APP_API_URL}/s3files/${fileKey}`);
      fetchFiles();
    } catch (error) {
      console.error('Dosya silinirken bir hata oluştu:', error);
    }
  };

  const handleBulkDelete = async () => {
    const isConfirmed = window.confirm('Seçili dosyaları silmek istediğinizden emin misiniz?');

    if (!isConfirmed || selectedFiles.length === 0) {
      return;
    }

    try {
      await Promise.all(selectedFiles.map(fileKey => api.delete(`${process.env.REACT_APP_API_URL}/s3files/${fileKey}`)));
      fetchFiles();
      setSelectedFiles([]);
      toast.success('Seçili dosyalar başarıyla silindi.');
    } catch (error) {
      console.error('Dosyalar silinirken bir hata oluştu:', error);
      toast.error('Bir hata oluştu, dosyalar silinemedi.');
    }
  };

  // Arama sonucuna göre filtrelenmiş dosyalar
  const filteredFiles = files.filter(file =>
    file.key.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sayfa başına dosya gösterimini sağlayan fonksiyon
  const getPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredFiles.slice(startIndex, endIndex);
  };

  // Toplam sayfa sayısını hesaplayan fonksiyon
  const totalPages = Math.ceil(filteredFiles.length / itemsPerPage);

  // Sayfa geçiş fonksiyonları
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Belirli bir sayfaya gitme fonksiyonu
  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  // Dosya seçme işlemi
  const toggleFileSelection = (fileKey: string) => {
    setSelectedFiles(prevSelected =>
      prevSelected.includes(fileKey)
        ? prevSelected.filter(key => key !== fileKey) // Seçiliyse çıkar
        : [...prevSelected, fileKey] // Seçili değilse ekle
    );
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
      let endPage = startPage + maxPageNumbersToShow - 1;

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = endPage - maxPageNumbersToShow + 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 1) {
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }

      if (endPage < totalPages) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return (
      <div className="flex justify-center space-x-2 mt-4">
        {pageNumbers.map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === 'number' && goToPage(page)}
            className={`px-3 py-1 rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
            disabled={typeof page !== 'number'}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex">
      <div className="fixed top-0 left-0 w-64 h-screen bg-gray-800 text-white z-50">
        <Sidebar />
      </div>

      <div className="ml-64 w-full min-h-screen bg-white p-8 mt-10">
        <ToastContainer position="bottom-right" autoClose={5000} />

        <div className="flex justify-between items-center mb-4">
          <div className="mt-2 flex items-center border border-gray-300 rounded overflow-hidden mb-4 max-w-xs">
            <div className="p-2 bg-white-100">
              <GrSearch />
            </div>
            <input
              type="text"
              placeholder="Dosya adı ara..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1); // Yeni arama yapıldığında ilk sayfaya geçiş
              }}
              className="p-2 flex-grow outline-69"
            />
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleOpenPopup}
              className="bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-300 rounded shadow hover:bg-gray-100"
            >
              Yeni Ekle
            </button>
            <button
              onClick={handleBulkDelete}
              className="bg-red-600 text-white font-semibold py-2 px-4 border border-red-600 rounded shadow hover:bg-red-700"
              disabled={selectedFiles.length === 0}
            >
              Seçili Sil ({selectedFiles.length})
            </button>
          </div>
        </div>

        <table className="w-full text-left table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2 text-[#192055]">#</th>
              <th className="px-4 py-2 text-[#192055]">Dosya Adı</th>
              <th className="px-4 py-2 text-center text-[#192055]">Medya Türü</th>
              <th className="px-4 py-2 text-center text-[#192055]">Boyut</th>
              <th className="px-4 py-2 text-center text-[#192055]">Eklenme Tarihi</th>
              <th className="px-4 py-2 text-center text-[#192055]">Görüntüleme</th>
              <th className="px-4 py-2 text-center text-[#192055]">Sil</th>
              <th className="px-4 py-2 text-center text-[#192055]">Seç</th>
            </tr>
          </thead>
          <tbody>
            {getPageData().map((file, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="px-4 py-2">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                <td className="px-4 py-2">{file.key}</td>
                <td className="px-4 py-2 text-center">{getMediaType(file.key)}</td>
                <td className="px-4 py-2 text-center">{(file.size / 1024).toFixed(2)} KB</td>
                <td className="px-4 py-2 text-center">{new Date(file.lastModified).toLocaleDateString('tr-TR')}</td>
                <td className="px-4 py-2 text-center">
                  <button
                    onClick={() => handleOpenViewer(file.url)}
                    className="text-blue-600 hover:underline"
                  >
                    Göster
                  </button>
                </td>
                <td className="px-4 py-2 text-center">
                  <button onClick={() => handleDelete(file.key)} className="text-red-600 hover:text-red-800">
                    <FaTrash />
                  </button>
                </td>
                <td className="px-4 py-2 text-center">
                  <input
                    type="checkbox"
                    checked={selectedFiles.includes(file.key)}
                    onChange={() => toggleFileSelection(file.key)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Sayfalama Butonları */}
        <div className="flex justify-center space-x-4 mt-4">
          <button
            onClick={goToPreviousPage}
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400"
            disabled={currentPage === 1}
          >
            Önceki Sayfa
          </button>
          <button
            onClick={goToNextPage}
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400"
            disabled={currentPage === totalPages}
          >
            Sonraki Sayfa
          </button>
        </div>

        {/* Sayfa Numaralandırma */}
        {renderPagination()}

        <NewMediaPopup isOpen={isPopupOpen} onClose={handleClosePopup} />
        <MediaViewer isOpen={isViewerOpen} onClose={handleCloseViewer} fileUrl={selectedFileUrl} />
      </div>
    </div>
  );
};

export default GalleryListPage;