import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector';
import FontTitleCardSection from '../../UI/sections/FontTitleCardSection';
import { S3File } from '../../services/types';

interface FontTitleCardFormProps {
    onSave: (data: any) => void;
}

const FontTitleCardForm: React.FC<FontTitleCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [title, setTitle] = useState('');
    const [fontFamily, setFontFamily] = useState('Arial, sans-serif');
    const [fontSize, setFontSize] = useState(40); // Varsayılan font boyutu
    const [fontWeight, setFontWeight] = useState('normal'); // Varsayılan font ağırlığı
    const [logoMedia, setLogoMedia] = useState<S3File | null>(null);
    const [isLogoSelectorOpen, setIsLogoSelectorOpen] = useState(false);

    const handleSave = async () => {
        if (!logoMedia) return;

        const componentData = {
            designId: id,
            componentName: 'FontTitleCard',
            componentData: { title, fontFamily, fontSize, fontWeight, logoMedia: logoMedia.url },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleLogoSelect = (file: S3File) => {
        setLogoMedia(file);
        setIsLogoSelectorOpen(false);
    };

    return (
        <div>
            {/* Başlık Alanı */}
            <div className="mb-4">
                <label className="block text-gray-700">Başlık</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Başlık Girin"
                />
            </div>

            {/* Font Ailesi Alanı */}
            <div className="mb-4">
                <label className="block text-gray-700">Font Ailesi</label>
                <select
                    value={fontFamily}
                    onChange={(e) => setFontFamily(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                >
                    <option value="Arial, sans-serif">Arial</option>
                    <option value="'Courier New', monospace">Courier New</option>
                    <option value="'Georgia', serif">Georgia</option>
                    <option value="'Times New Roman', serif">Times New Roman</option>
                    <option value="'Radhanji', cursive">Radhanji</option>
                </select>
            </div>

            {/* Font Boyutu Alanı */}
            <div className="mb-4">
                <label className="block text-gray-700">Font Boyutu (px)</label>
                <input
                    type="number"
                    value={fontSize}
                    onChange={(e) => setFontSize(Number(e.target.value))}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Font Boyutu Girin"
                    min={10}
                    max={100}
                />
            </div>

            {/* Font Kalınlığı Alanı */}
            <div className="mb-4">
                <label className="block text-gray-700">Font Kalınlığı</label>
                <select
                    value={fontWeight}
                    onChange={(e) => setFontWeight(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                >
                    <option value="light">İnce (Light)</option>
                    <option value="normal">Normal</option>
                    <option value="bold">Kalın (Bold)</option>
                </select>
            </div>

            {/* Logo Medya Alanı */}
            <div className="mb-4">
                <label className="block text-gray-700">Logo Medya</label>
                <button
                    onClick={() => setIsLogoSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                >
                    <span className="text-[#091E42]">{logoMedia ? logoMedia.key : 'Logo Seç'}</span>
                    <svg
                        className="w-4 h-4 text-[#091E42]"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>
            </div>

            <MediaSelector
                isOpen={isLogoSelectorOpen}
                onSelect={handleLogoSelect}
                onClose={() => setIsLogoSelectorOpen(false)}
            />

            {/* Önizleme Alanı */}
            <div className="my-6">
                <h2 className="text-lg font-semibold mb-4">Önizleme</h2>
                <div className="border border-gray-300 rounded-lg p-4">
                    <FontTitleCardSection
                        componentData={{
                            title,
                            fontFamily,
                            fontSize,
                            fontWeight,
                            logoMedia: logoMedia?.url || '',
                        }}
                    />
                </div>
            </div>

            {/* Kaydet Butonu */}
            <div className="flex justify-end space-x-4">
                <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-red-800 text-white rounded-lg shadow"
                >
                    Ekle
                </button>
            </div>
        </div>
    );
};

export default FontTitleCardForm;
