import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Medya seçici bileşeni
import { S3File } from '../../services/types'; // S3File türü
import TextMiniSliderCardSection from '../../UI/sections/TextMiniSliderCardSection'; // Önizleme bileşeni

interface TextMiniSliderCardFormProps {
    onSave: (data: any) => void;
}

const TextMiniSliderCardForm: React.FC<TextMiniSliderCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [cards, setCards] = useState([
        { buttonText: '', buttonUrl: '', media: null as S3File | null, text: '' }
    ]);
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState<number | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const MAX_TEXT_LENGTH = 150;

    const handleAddCard = () => {
        setCards([...cards, { buttonText: '', buttonUrl: '', media: null, text: '' }]);
    };

    const handleCardChange = (index: number, field: string, value: string) => {
        const updatedCards = cards.map((card, i) =>
            i === index ? { ...card, [field]: value } : card
        );
        setCards(updatedCards);
    };

    const handleMediaSelect = (index: number, file: S3File) => {
        const updatedCards = cards.map((card, i) =>
            i === index ? { ...card, media: file } : card
        );
        setCards(updatedCards);
        setIsMediaSelectorOpen(null);
    };

    const handleRemoveCard = (index: number) => {
        setCards(cards.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        const componentData = {
            designId: id,
            componentName: 'TextMiniSliderCard',
            componentData: {
                cards: cards.map((card) => ({
                    ...card,
                    media: card.media?.url || '',
                })),
            },
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    return (
        <div className="relative bg-white p-4 rounded-lg shadow">
            {cards.map((card, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">Card {index + 1}</h3>
                        <button
                            onClick={() => handleRemoveCard(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded"
                        >
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Media</label>
                        <button
                            onClick={() => setIsMediaSelectorOpen(index)}
                            className="relative w-full p-2 border border-[#DFE2E6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-[#091E42]">{card.media ? card.media.key : 'Select Media'}</span>
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Text</label>
                        <textarea
                            value={card.text}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_TEXT_LENGTH)
                                    handleCardChange(index, 'text', e.target.value);
                            }}
                            className="w-full px-4 py-2 border rounded-md resize-none h-24"
                            placeholder="Enter text"
                        />
                        <p className="text-sm text-gray-500">{card.text.length}/{MAX_TEXT_LENGTH}</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Button Text</label>
                        <input
                            type="text"
                            value={card.buttonText}
                            onChange={(e) => handleCardChange(index, 'buttonText', e.target.value)}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Enter button text"
                        />
                        <p>Girilmesi Zorunlu Değil!!!</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Button URL</label>
                        <input
                            type="text"
                            value={card.buttonUrl}
                            onChange={(e) => handleCardChange(index, 'buttonUrl', e.target.value)}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Enter button URL"
                        />
                        <p>Girilmesi Zorunlu Değil!!!</p>
                    </div>
                    <MediaSelector
                        isOpen={isMediaSelectorOpen === index}
                        onSelect={(file: S3File) => handleMediaSelect(index, file)}
                        onClose={() => setIsMediaSelectorOpen(null)}
                    />
                </div>
            ))}
            <button
                onClick={handleAddCard}
                className="px-4 py-2 bg-transparent border border-gray-400 text-gray-700 rounded-lg shadow mt-4"
            >
                Text Mini Card Ekle
            </button>
            {/* Sağ Alt Köşe Butonlar */}
            <div className="flex justify-end space-x-4 mt-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>
            {isPreviewOpen && (
     <div
     className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
     onClick={() => setIsPreviewOpen(false)}
 >
          {/* Modal İçeriği */}
          <div
            className="relative bg-transparent flex items-center justify-center w-full max-w-[85%] h-[90vh]" // Yükseklik 90vh olarak ayarlandı
            onClick={(e) => e.stopPropagation()} // Modal içinde tıklamayı durdurur
        >
        <button
            onClick={() => setIsPreviewOpen(false)}
            className="absolute top-4 right-4 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 transition-all z-50"
            style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>


            {/* Kart Önizlemesi */}
            <div
            className="flex items-center justify-center w-full h-full"
            onClick={(e) => e.stopPropagation()} // Modal içi tıklama engellemesi
        >
               <TextMiniSliderCardSection
                    componentData={{
                        cards: cards.map((card) => ({
                            ...card,
                            media: card.media?.url || '',
                        })),
                    }}
                />
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default TextMiniSliderCardForm;
