import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface SpacingFormProps {
    onSave: (data: any) => void;
}

const SpacingForm: React.FC<SpacingFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [spacingValue, setSpacingValue] = useState<number>(0);
    const [spacingCount, setSpacingCount] = useState<number>(1);

    const handleSave = async () => {
        try {
            // spacingCount kadar spacingValue içeren istek gönder
            const responses = await Promise.all(
                Array.from({ length: spacingCount }).map(async (_, index) => {
                    const componentData = {
                        designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
                        componentName: `Spacing ${index + 1}`, // Her bileşene farklı bir isim ver
                        componentData: { value: spacingValue }, // spacingValue'yu data olarak gönder
                    };

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
                    return response.data; // Backend'den dönen veriyi döndür
                })
            );

            onSave(responses); // Tüm yanıtları onSave ile ilet
        } catch (error) {
            console.error('Veriler kaydedilirken bir hata oluştu:', error);
        }
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Boşluk Değeri (px)</label>
                <input
                    type="number"
                    value={spacingValue}
                    onChange={(e) => setSpacingValue(parseInt(e.target.value))}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Boşluk Miktarı Girin"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Kaç Adet</label>
                <input
                    type="number"
                    value={spacingCount}
                    onChange={(e) => setSpacingCount(parseInt(e.target.value))}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Adet Girin"
                />
            </div>
            <div className="flex justify-end">
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Boşlukları Kaydet
                </button>
            </div>
        </div>
    );
};

export default SpacingForm;
