import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface Clooned3DViewerProps {
    componentData: {
        features: string;
        oid: string;
        text?: string;
    };
}

const Clooned3DViewerLargeCard: React.FC<Clooned3DViewerProps> = ({ componentData }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const [iframeContent, setIframeContent] = useState<string>('');
    const { features, oid } = componentData;

    useEffect(() => {
        if (inView) {
            setIframeContent(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <title>3D Viewer</title>
                    <style>
                        body, html {
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                            background: transparent;
                        }
                        clooned-object {
                            width: 100%;
                            height: 100%;
                        }
                    </style>
                    <script src="https://clooned.com/wp-content/uploads/cloons/scripts/clooned.js" async></script>
                </head>
                <body>
                    <clooned-object
                        features="${features}"
                        oid="${oid}"
                        width="100%"
                        height="100%"
                    ></clooned-object>
                </body>
                </html>
            `);
        }
    }, [inView, features, oid]);

    return (
        <motion.div
            ref={ref}
            className="p-5 bg-white rounded-3xl shadow-lg mb-8 flex justify-center items-center w-full md:w-[85vw] h-[50vh] md:h-[70vh] mx-auto"
            initial={{ opacity: 0, y: 100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: 'easeOut' }}
        >
            <div className="w-full h-full rounded-3xl overflow-hidden">
                {iframeContent && (
                    <iframe
                        srcDoc={iframeContent}
                        className="w-full h-full"
                        style={{
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                        title="3D Model Viewer"
                        scrolling="no"
                    />
                )}
            </div>
        </motion.div>
    );
};

export default Clooned3DViewerLargeCard;
