import React, { useState } from 'react';
import LargePopupCardForm from './componentmodals/LargePopupCardForm';
import CTACardForm from './componentmodals/CTACardForm';
import LargeFlipCardForm from './componentmodals/LargeFlipCardForm';
import LargeScalableCardForm from './componentmodals/LargeScalableCardForm';
import FullTextCardForm from './componentmodals/FullTextCardForm';
import ReelsCardSliderForm from './componentmodals/ReelsCardSliderForm';
import RightTextCardForm from './componentmodals/RightTextCardForm';
import LeftTextCardForm from './componentmodals/LeftTextCardForm';
import TopTextCardForm from './componentmodals/TopTextCardForm';
import TwinFlipCardForm from './componentmodals/TwinFlipCardForm';
import AccordionRightCardForm from './componentmodals/AccordionRightCardForm';
import LargeTopTitleHeroCardForm from './componentmodals/LargeTopTitleHeroCardForm';
import TwinTopTitleHeroCardForm from './componentmodals/TwinTopTitleHeroCardForm';
import FullScreenCardSliderForm from './componentmodals/FullScreenCardSliderForm';
import MiniCardSliderForm from './componentmodals/MiniCardSliderForm';
import InfoCardSliderForm from './componentmodals/InfoCardSliderForm';

import LargeCardForm from './componentmodals/LargeCardForm';
import TitleCardForm from './componentmodals/TitleCardForm';
import TwinCardForm from './componentmodals/TwinCardForm';
import HeaderForm from './componentmodals/HeaderForm';
import BottomTextCardForm from './componentmodals/BottomTextCardForm';
import useComponentStore from '../store/useComponentStore';
import SearchComponentForm from './componentmodals/SearchComponentForm';
import SpacingForm from './componentmodals/SpacingForm';
import ReelsCardSliderFormWithExplore from './componentmodals/ReelsCardSliderFormWithExploreForm';
import BannerForm from './componentmodals/BannerForm';
import largeFlipCard from '../assets/images/largeflipcard.png';
import Header from '../assets/images/Header.png';
import accordionrightcard from '../assets/images/accordionrightcard.png';
import banner from '../assets/images/banner.png';
import bottomtext from '../assets/images/bottomtextcard.png';
import cta from '../assets/images/cta.png';
import fullscreencardslider from '../assets/images/fullscreencardslider.png';
import fulltext from '../assets/images/fulltext.png';
import infocardslider from '../assets/images/infocardslider.png';
import largecard from '../assets/images/largecard.png';
import largepopupcard from '../assets/images/largepopupcard.png';
import largescalablecard from '../assets/images/largescalablecard.png';
import largetoptitleherocard from '../assets/images/largetoptitleherocard.png';
import lefttextcard from '../assets/images/lefttextcard.png';
import minicardslider from '../assets/images/minicardslider.png';
import reelscardslider from '../assets/images/reelscardslider.png';
import righttextcard from '../assets/images/righttextcard.png';
import reelscardsliderwithexplore from '../assets/images/reelscardsliderwithexplore.png';
import twinflipcard from '../assets/images/twinflipcard.png';
import twinTopTitleHeroCard from '../assets/images/twintoptitleherocard.png';
import Title from '../assets/images/Title.png';
import toptextcard from '../assets/images/toptextcard.png';
import twincard from '../assets/images/twincard.png';
import spacing from '../assets/images/spacing.png';
import searchcomponent from '../assets/images/SearchComponent.png';

import Clooned3DViewerForm from './componentmodals/Clooned3DViewerForm';
import textminislidercard from "../assets/images/textminislidercard.png"
import TextMiniSliderCardForm from './componentmodals/TextMiniSliderCardForm';
import SponsorCardForm from './componentmodals/SponsorCardForm';
import sponsorcard from "../assets/images/sponsorcard.png"
import FontTitleCardForm from "./componentmodals/FontTitleCardForm";
import accordion3dcard from "../assets/images/accordion3dcard.png"
import righttextcard3dviewer from "../assets/images/right3dcard.png"
import largecard3dviewer from "../assets/images/large3dcard.png"
import left3dcard from "../assets/images/left3dcard.png"
import fonttitlecard from "../assets/images/Font Title Card.png"
import Clooned3DLargeCardForm from './componentmodals/Clooned3DLargeCardForm';
import RightTextCard3DViewerForm from './componentmodals/RightTextCard3DViewerForm';
import Accordion3DViewerForm from './componentmodals/Accordion3DViewerForm';

interface ComponentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (component: any) => void;
}

const ComponentModal: React.FC<ComponentModalProps> = ({ isOpen, onClose }) => {
    const [selectedComponent, setSelectedComponent] = useState<string | null>(null);
    const [hoveredComponent, setHoveredComponent] = useState<string | null>(null);
    const [hoverPosition, setHoverPosition] = useState<string>('right'); // Hover görseli için pozisyon
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false); // Formun açılma durumunu kontrol ediyoruz.

    const { setComponentData } = useComponentStore();

    const handleSave = (data: any) => {
        if (selectedComponent) {
            setComponentData(selectedComponent, data);
            setIsFormOpen(false); // Formu kaydettikten sonra kapatıyoruz.
            setSelectedComponent(null);
        }
    };

    const handleComponentClick = (name: string) => {
        setSelectedComponent(name);
        setIsFormOpen(true); // Component seçildiğinde form popup'ı açılır.
    };

    // Fareyle elementin üzerine gelindiğinde hover pozisyonunu ayarlama
    const handleMouseEnter = (option: string, image: string, event: React.MouseEvent<HTMLDivElement>) => {
        const screenWidth = window.innerWidth; // Ekranın genişliği
        const elementX = event.clientX; // Fare ile üzerine gelinen elementin X pozisyonu

        if (elementX > screenWidth / 2) {
            setHoverPosition('left'); // Eğer sağa yakınsa hover solda gösterilsin
        } else {
            setHoverPosition('right'); // Eğer sola yakınsa hover sağda gösterilsin
        }

        setHoveredComponent(image); // Hover görselini ayarla
    };

    const componentOptions = [
        { name: 'Accordion Right Card', image: accordionrightcard },
        { name: 'Banner', image: banner },
        { name: 'Bottom Text Card', image: bottomtext },
        { name: 'CTA Card', image: cta },
        { name: 'Full Screen Card Slider', image: fullscreencardslider },
        { name: 'Full Text', image: fulltext },
        { name: 'Header', image: Header },
        { name: 'Info Card Slider', image: infocardslider },
        { name: 'Large Card', image: largecard },
        { name: 'Large Flip Card', image: largeFlipCard },
        { name: 'Large Popup Card', image: largepopupcard },
        { name: 'Large Scalable Card', image: largescalablecard },
        { name: 'Large Top Title Hero Card', image: largetoptitleherocard },
        { name: 'Left Text Card', image: lefttextcard },
        { name: 'Mini Card Slider', image: minicardslider },
        { name: 'Reels Card Slider', image: reelscardslider },
        { name: 'Reels Card Slider with Explore', image: reelscardsliderwithexplore },
        { name: 'Right Text Card', image: righttextcard },
        { name: 'Search Component', image: searchcomponent },
        { name: 'Spacing', image: spacing },
        
        { name: 'Title Card', image: Title },
        { name: 'Top Text Card', image: toptextcard },
        { name: 'Twin Card', image: twincard },
        { name: 'Twin Flip Card', image: twinflipcard },
        { name: 'Twin Top Title Hero Card', image: twinTopTitleHeroCard },
        
        { name: 'Left Text Card 3D Viewer', image: left3dcard },
        { name: 'Large Card 3D Viewer', image: largecard3dviewer },
        { name: "Text Mini Slider Card", image: textminislidercard },
        { name: "Font Title Card", image: fonttitlecard },
        { name: "Right Text Card 3D Viewer", image: righttextcard3dviewer },
        { name: "Accordion Card 3D Viewer", image: accordion3dcard },
    ];
    const hoverDescriptions: { [key: string]: string } = {
        'Large Popup Card': 'Bu bir Large Popup Card bileşenidir. Pop-up stilinde geniş kart.',
        'Large Flip Card': 'Bu bir Large Flip Card bileşenidir. Kartlar arasında flip animasyonu.',
        'Large Scalable Card': 'Bu bir Large Scalable Card bileşenidir. Ölçeklenebilir geniş kart.',
        'Full Text': 'Bu bir Full Text bileşenidir. Tam metin alanı.',
        'Reels Card Slider': 'Bu bir Reels Card Slider bileşenidir. Reels stilinde kaydırma.',
        'Right Text Card': 'Bu bir Right Text Card bileşenidir. Sağda metin kartı.',
        'Left Text Card': 'Bu bir Left Text Card bileşenidir. Solda metin kartı.',
        'Top Text Card': 'Bu bir Top Text Card bileşenidir. Üstte metin kartı.',
        'Twin Flip Card': 'Bu bir Twin Flip Card bileşenidir. İkili flip animasyonu kart.',
        'Accordion Right Card': 'Bu bir Accordion Right Card bileşenidir. Sağda açılır içerik kartı.',
        'Large Top Title Hero Card': 'Bu bir Large Top Title Hero Card bileşenidir. Büyük başlıklı hero kart.',
        'Twin Top Title Hero Card': 'Bu bir Twin Top Title Hero Card bileşenidir. İkili hero kart.',
        'Full Screen Card Slider': 'Bu bir Full Screen Card Slider bileşenidir. Tam ekran kart kaydırma.',
        'Mini Card Slider': 'Bu bir Mini Card Slider bileşenidir. Küçük boyutlu kart kaydırma.',
        'CTA Card': 'Bu bir CTA Card bileşenidir. Call to Action kartı.',
        'Header': 'Bu bir Header bileşenidir. Sayfa başlığı için kullanılır.',
        'Info Card Slider': 'Bu bir Info Card Slider bileşenidir. Bilgi kartlarını kaydır.',
        'Title Card': 'Bu bir Title Card bileşenidir. Başlık kartı.',
        'Twin Card': 'Bu bir Twin Card bileşenidir. İkili kart.',
        'Large Card': 'Bu bir Large Card bileşenidir. Büyük boyutlu kart.',
        'Bottom Text Card': 'Bu bir Bottom Text Card bileşenidir. Alt metin kartı.',
        'Search Component': 'Bu bir Search Component bileşenidir. Arama bileşeni.',
        'Spacing': 'Bu bir Spacing bileşenidir. Boşluk eklemek için kullanılır.',
        'Reels Card Slider with Explore': 'Bu bir Reels Card Slider with Explore bileşenidir.',
        'Banner': 'Bu bir Banner bileşenidir. Sayfa banner görseli.',
        'Left Text Card 3D Viewer': 'Bu bir 3D bileşenidir. Sayfa 3D görseli.',
        'Large Card 3D Viewer': 'Bu bir 3D bileşenidir. Sayfa 3D görseli.',
        'Right Text Card 3D Viewer': 'Bu bir 3D bileşenidir. Sayfa 3D görseli.',
        'Accordion Card 3D Viewer': 'Bu bir 3D bileşenidir. Sayfa 3D görseli.',
    };

    // Ekran boyutuna göre hover görsel boyutunu ayarlamak için
    const getHoverStyles = () => {
        const maxWidth = window.innerWidth * 0.4; // Ekran genişliğinin %40'ı
        const maxHeight = window.innerHeight * 0.6; // Ekran yüksekliğinin %60'ı

        return { maxWidth, maxHeight };
    };

    const renderForm = () => {
        switch (selectedComponent) {
            case 'Large Popup Card':
                return <LargePopupCardForm onSave={handleSave} />;
            case "Font Title Card":
                return <FontTitleCardForm onSave={handleSave} />;
            case 'Large Flip Card':
                return <LargeFlipCardForm onSave={handleSave} />;
            case 'Large Scalable Card':
                return <LargeScalableCardForm onSave={handleSave} />;
            case 'Full Text':
                return <FullTextCardForm onSave={handleSave} />;
            case 'Reels Card Slider':
                return <ReelsCardSliderForm onSave={handleSave} />;
            case 'Left Text Card 3D Viewer':
                return <Clooned3DViewerForm onSave={handleSave} />;
            case 'Right Text Card':
                return <RightTextCardForm onSave={handleSave} />;
            case 'Left Text Card':
                return <LeftTextCardForm onSave={handleSave} />;
            case 'Top Text Card':
                return <TopTextCardForm onSave={handleSave} />;
            case 'Twin Flip Card':
                return <TwinFlipCardForm onSave={handleSave} />;
            case 'Right Text Card 3D Viewer':
                return <RightTextCard3DViewerForm onSave={handleSave} />;
            case 'Accordion Card 3D Viewer':
                return <Accordion3DViewerForm onSave={handleSave} />;
            case 'Accordion Right Card':
                return <AccordionRightCardForm onSave={handleSave} />;
            case 'Large Top Title Hero Card':
                return <LargeTopTitleHeroCardForm onSave={handleSave} />;
            case 'Twin Top Title Hero Card':
                return <TwinTopTitleHeroCardForm onSave={handleSave} />;
            case 'Full Screen Card Slider':
                return <FullScreenCardSliderForm onSave={handleSave} />;
            case 'Mini Card Slider':
                return <MiniCardSliderForm onSave={handleSave} />;
            case 'Header':
                return <HeaderForm onSave={handleSave} />;
            case 'CTA Card':
                return <CTACardForm onSave={handleSave} />;
            case 'Info Card Slider':
                return <InfoCardSliderForm onSave={handleSave} />;

            case 'Title Card':
                return <TitleCardForm onSave={handleSave} />;
            case 'Twin Card':
                return <TwinCardForm onSave={handleSave} />;
            case 'Large Card':
                return <LargeCardForm onSave={handleSave} />;
            case 'Bottom Text Card':
                return <BottomTextCardForm onSave={handleSave} />;
            case 'Search Component':
                return <SearchComponentForm onSave={handleSave} />;
            case 'Spacing':
                return <SpacingForm onSave={handleSave} />;
        
            case 'Reels Card Slider with Explore':
                return <ReelsCardSliderFormWithExplore onSave={handleSave} />;
            case 'Banner':
                return <BannerForm onSave={handleSave} />;
            
            case "Text Mini Slider Card":
                return <TextMiniSliderCardForm onSave={handleSave} />;
            case "Sponsor Card":
                return <SponsorCardForm onSave={handleSave} />;
            case 'Large Card 3D Viewer':
                return <Clooned3DLargeCardForm onSave={handleSave} />;

            default:
                return null;
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"

        >
            <div
                className="bg-white rounded-xl shadow-lg p-6 w-[90vw] max-h-[90vh] overflow-y-auto relative"
                onClick={(e) => e.stopPropagation()} // Popup içine tıklanırsa kapanmayı engelliyoruz
            >
                {/* Başlık ve kapatma düğmesi */}
                <div className="relative mb-6">
                    <h2 className="text-3xl font-bold text-[#2b3674]">Component Ekle</h2>
                    <button
                        onClick={onClose}
                        className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>

                {/* Sol tarafta component seçenekleri, responsive grid */}
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pr-4">
                    {componentOptions.map((option) => (
                        <div
                            key={option.name}
                            onClick={() => handleComponentClick(option.name)} // Component'e tıklanınca form popup'ı açılıyor
                            onMouseEnter={(e) => handleMouseEnter(option.name, option.image, e)} // Hover konumunu ayarlama
                            onMouseLeave={() => setHoveredComponent(null)}
                            className="flex items-center space-x-4 p-3 cursor-pointer hover:bg-gray-200 rounded-lg"
                        >
                            <span className="text-[#2b3674] text-lg font-medium">{option.name}</span>
                        </div>
                    ))}
                </div>
            </div>

            {hoveredComponent && (
                <div
                    className={`fixed top-17 ${hoverPosition === 'right' ? 'right-10' : 'left-10'} bg-white shadow-lg p-4 rounded-lg z-50 flex flex-col items-center`}
                    style={getHoverStyles()} // Ekran boyutuna göre hover stilini ayarla
                >
                    {/* Görseli göster */}
                    <img
                        src={hoveredComponent}
                        alt="Component Preview"
                        className="w-full h-full object-contain" // Görselin taşmasını engelle ve tam olarak sığdır
                    />

                    {/* Hover açıklama metni */}
                    <p className="text-center mt-4 text-lg text-gray-700">
                        {/* hoveredComponent ismi `componentOptions` nesnesindeki `name` ile eşleşmeli */}
                        {hoverDescriptions[componentOptions.find(option => option.image === hoveredComponent)?.name || '']}
                    </p>
                </div>
            )}

            {isFormOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div
                        className="bg-white rounded-xl shadow-lg p-6 w-[50vw] max-h-[80vh] overflow-y-auto relative"
                        onClick={(e) => e.stopPropagation()} // Form popup'ı içinde tıklamayı engelliyoruz
                    >
                        {/* Seçilen component adını gösteren başlık */}
                        <div className="relative mb-4">
                            <h3 className="text-2xl font-bold text-[#2b3674]">{selectedComponent} Formu</h3>
                            <button
                                onClick={() => setIsFormOpen(false)} // Form popup'ı kapat
                                className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        {/* Seçilen formu render et */}
                        {renderForm()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ComponentModal;