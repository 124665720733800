import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CTACardSection from '../UI/sections/CTACardSection';
import FullScreenCardSliderSection from '../UI/sections/FullScreenCardSliderSection';
import FullTextCardSection from '../UI/sections/FullTextCardSection';
import HeaderSection from '../UI/sections/HeaderSection';
import AccordionRightCardSection from '../UI/sections/AccordionRightCardSection';
import InfoCardSliderSection from '../UI/sections/InfoCardSliderSection';
import MiniCardSliderSection from '../UI/sections/MiniCardSliderSection';
import ReelsCardSliderSection from '../UI/sections/ReelsCardSliderSection';
import TitleCardSection from '../UI/sections/TitleCardSection';
import TwinTopTitleHeroCardSection from '../UI/sections/TwinTopTitleHeroCardSection';
import LargeFlipCardSection from '../UI/sections/LargeFlipCardSection';
import LargePopupCardSection from '../UI/sections/LargePopupCardSection';
import LargeScalableCardSection from '../UI/sections/LargeScalableCardSection';
import TwinCardSection from '../UI/sections/TwinCardSection';
import LargeTopTitleHeroCardSection from '../UI/sections/LargeTopTitleHeroCardSection';
import RightTextCardSection from '../UI/sections/RightTextCardSection';
import LeftTextCardSection from '../UI/sections/LeftTextCardSection';
import TopTextCardSection from '../UI/sections/TopTextCardSection';
import LargeCardSection from '../UI/sections/LargeCardSection';
import TwinFlipCardSection from '../UI/sections/TwinFlipCardSection';
import BottomTextCardSection from '../UI/sections/BottomTextCardSection';
import ReelsCardSliderWithExploreSection from '../UI/sections/ReelsCardSliderWithExploreSection';
import ProductCardSection from '../UI/sections/ProductCardSection';
import SearchComponentSection from '../UI/sections/SearchComponentSection';
import SpacingSection from '../UI/sections/SpacingSection';
import BannerSection from '../UI/sections/BannerSection';
import CompanyNameSection from '../UI/sections/CompanyNameSection';
import { FaCopy } from 'react-icons/fa';
import ShareLinks from '../components/ShareLinks';
import ServiceCardSection from '../UI/sections/ServiceCardSection';
import SponsorCardSection from '../UI/sections/SponsorCardSection';
import TextMiniSliderCardSection from '../UI/sections/TextMiniSliderCardSection';
import FontTitleCardSection from '../UI/sections/FontTitleCardSection';
import RightTextCard3DViewer from '../UI/sections/RightTextCard3DViewer';
import Accordion3DViewerSection from '../UI/sections/Accordion3DViewerSection';



const LaunchPage: React.FC<any> = (components: any) => {
    const [isCopied, setIsCopied] = useState(false);  // URL kopyalandı mı durumunu tutar

    const handleCopyUrl = () => {
        const fullUrl = window.location.href; // Geçerli sayfanın tam URL'sini al
        navigator.clipboard.writeText(fullUrl)
            .then(() => {
                setIsCopied(true);  // URL kopyalandı
                setTimeout(() => setIsCopied(false), 3000); // 3 saniye sonra mesajı kaldır
            })
            .catch(err => {
                console.error("URL kopyalanamadı: ", err); // Hata durumunda
            });
    };
    console.log("components", components);


    return (
        <div>
            <Navbar />
          <ShareLinks/>
            {components.components.map((component: any, index: number) => {
                const sectionData = component.componentData;

                switch (true) {
                    case component.componentName.startsWith('CTA Card'):
                        return (
                            <CTACardSection
                                key={`cta-card-${index}`}
                                title={sectionData.title}
                                subtitle={sectionData.subtitle}
                                buttonText={sectionData.buttonText}
                                buttonUrl={sectionData.buttonUrl}
                            />
                        );

                        case component.componentName.startsWith("FontTitleCard"):
                            return (
                                <FontTitleCardSection
                                key={`font-title-card-${index}`}
                                componentData={sectionData}
                            />
                            );
    

                    case component.componentName.startsWith('Banner'):
                        return (
                            <BannerSection
                                key={`banner-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith(
                        "SponsorCard"
                    ):
                        return (
                            <SponsorCardSection
                                key={`sponsor-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith("Right Text 3D Viewer"):
                        return (
                            <RightTextCard3DViewer
                                key={`Clooned-3D-Right-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith("Accordion 3D Viewer"):
                        return (
                            <Accordion3DViewerSection
                                key={`Clooned-3D-Accordion-card-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith(
                        "TextMiniSliderCard"
                    ):
                        return (
                            <TextMiniSliderCardSection
                                key={`text-mini-slider-card-${index}`}
                                componentData={sectionData}


                            />
                        );

                        case component.componentName.startsWith('Service Card'):
                            return (
                                <ServiceCardSection
                                    key={`service-card-${index}`}
                                    componentData={sectionData}
                                    />
                            );

                    case component.componentName.startsWith('Spacing'):
                        return (
                            <SpacingSection
                                key={`spacing-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith('Search Component'):
                        return (
                            <SearchComponentSection
                                key={`search-component-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith('Bottom Text Card'):
                        return (
                            <BottomTextCardSection
                                key={`bottom-text-card-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith('Explorer'):
                        return (
                            <ReelsCardSliderWithExploreSection
                                key={`reels-card-slider-with-explore-${index}`}
                                componentData={sectionData}
                            />
                        )
                    case component.componentName.startsWith('Full Screen Card Slider'):
                        return (
                            <FullScreenCardSliderSection
                                key={`fullscreen-slider-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Full Text'):
                        return (
                            <FullTextCardSection
                                key={`full-text-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Header'):
                        return (
                            <HeaderSection
                                key={`header-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Accordion Right Card'):
                        return (
                            <AccordionRightCardSection
                                key={`accordion-right-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Info Card Slider'):
                        return (
                            <InfoCardSliderSection
                                key={`info-card-slider-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Mini Card Slider'):
                        return (
                            <MiniCardSliderSection
                                key={`mini-card-slider-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Reels Card Slider'):
                        return (
                            <ReelsCardSliderSection
                                key={`reels-card-slider-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Title'):
                        return (
                            <TitleCardSection
                                key={`title-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Twin Top Title Hero Card'):
                        return (
                            <TwinTopTitleHeroCardSection
                                key={`twin-top-title-hero-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Large Flip Card'):
                        return (
                            <LargeFlipCardSection
                                key={`large-flip-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Large Popup Card'):
                        return (
                            <LargePopupCardSection
                                key={`large-popup-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Large Scalable Card'):
                        return (
                            <LargeScalableCardSection
                                key={`large-scalable-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Large Top Title Hero Card'):
                        return (
                            <LargeTopTitleHeroCardSection
                                key={`large-top-title-hero-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Right Text Card'):
                        return (
                            <RightTextCardSection
                                key={`right-text-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Left Text Card'):
                        return (
                            <LeftTextCardSection
                                key={`left-text-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Twin Card'):
                        return (
                            <TwinCardSection
                                key={`twin-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Top Text Card'):
                        return (
                            <TopTextCardSection
                                key={`top-text-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Large Card'):
                        return (
                            <LargeCardSection
                                key={`large-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith('Twin Flip Card'):
                        return (
                            <TwinFlipCardSection
                                key={`twin-flip-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                        case component.componentName.startsWith('CompanyNameComponent'):
                            return (
                                <CompanyNameSection
                                    key={`company-name-component-${index}`}
                                    componentData={sectionData}
                                    />
                            );
                            case component.componentName.startsWith('Product Card'):
                                return (
                                    <ProductCardSection
                                        key={`product-card-${index}`}
                                        componentData={sectionData}
                                        />
                                );
                    
                    default:
                        return null;
                }
            })}

            <Footer />



        </div>
    );
};

export default LaunchPage;