import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface FontTitleCardSectionProps {
    componentData: {
        title: string;
        fontFamily: string;
        fontSize: number;
        fontWeight: string;
        logoMedia: string;
    };
}

const FontTitleCardSection: React.FC<FontTitleCardSectionProps> = ({ componentData }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    return (
        <div
            ref={ref}
            className="flex justify-between items-center w-full max-w-[95vw] h-auto mx-auto bg-white relative px-4 md:px-6 py-4"
            style={{ overflow: 'hidden' }}
        >
            <motion.h1
                className="text-gray-800"
                style={{
                    fontFamily: componentData.fontFamily,
                    fontSize: `${componentData.fontSize}px`,
                    fontWeight: componentData.fontWeight, // Dinamik font kalınlığı
                    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)',
                }}
                initial={{ x: -100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: 'easeOut' }}
            >
                {componentData.title}
            </motion.h1>

            <motion.div
                className="flex items-center h-auto pr-8"
                initial={{ x: 100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: 'easeOut' }}
            >
                <img
                    src={componentData.logoMedia}
                    alt="Logo"
                    className="object-contain w-[70px] h-[70px] md:w-[120px] md:h-[120px]"
                />
            </motion.div>
        </div>
    );
};

export default FontTitleCardSection;
