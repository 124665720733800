import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface Clooned3DViewerProps {
    componentData: {
        features: string;
        oid: string;
        text?: string;
    };
}

const RightTextCard3DViewer: React.FC<Clooned3DViewerProps> = ({ componentData }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const [iframeContent, setIframeContent] = useState<string>('');
    const { features, oid, text = '' } = componentData;

    useEffect(() => {
        if (inView) {
            setIframeContent(` 
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <title>3D Viewer</title>
                    <style>
                        body {
                            margin: 0;
                            overflow: hidden;
                        }
                    </style>
                    <script src="https://clooned.com/wp-content/uploads/cloons/scripts/clooned.js" async></script>
                </head>
                <body>
                    <clooned-object
                        features="${features}"
                        oid="${oid}"
                        width="100%"
                        height="600px"
                    ></clooned-object>
                </body>
                </html>
            `);
        }
    }, [inView, features, oid]);

    const textVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0, transition: { duration: 1, ease: 'easeInOut' } },
    };

    return (
        <motion.div
            ref={ref}
            className="flex flex-col md:flex-row items-stretch bg-white rounded-3xl shadow-lg w-full max-w-screen-xl mx-auto"
            initial={{ opacity: 0, y: 100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: 'easeOut' }}
            style={{
                height: '70vh',
                width: '100%',
            }}
        >
            {/* Left side: Full-width 3D Viewer iframe with only left corners rounded */}
            <div className="w-full md:w-1/2 h-full rounded-b-3xl md:rounded-l-3xl md:rounded-br-none overflow-hidden">
                {iframeContent && (
                    <motion.iframe
                        srcDoc={iframeContent}
                        className="w-full h-full overflow-hidden"
                        style={{
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                        title="3D Model Viewer"
                        scrolling="no"
                        whileHover={{ scale: 1.03 }}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                    />
                )}
            </div>

            {/* Right side: Text content with only right corners rounded */}
            <div className="bg-gray-100 w-full md:w-1/2 flex items-center justify-center rounded-t-3xl md:rounded-r-3xl md:rounded-tl-none">
                <motion.p
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    className="text-xl text-gray-800 leading-relaxed text-left break-words p-8"
                    style={{
                        maxWidth: '90%',
                        fontSize: 'clamp(18px, 4vw, 28px)',
                        lineHeight: '1.5',
                        fontFamily: 'Radhanji, sans-serif',
                    }}
                >
                    {text}
                </motion.p>
            </div>
        </motion.div>
    );
};

export default RightTextCard3DViewer;
