import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Accordion3DViewerSection from '../../UI/sections/Accordion3DViewerSection';

interface Accordion3DViewerFormProps {
    onSave: (data: any) => void;
}

const Accordion3DViewerForm: React.FC<Accordion3DViewerFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
 const [accordionSections, setAccordionSections] = useState([{ title: '', subtitle: '', error: '' }]); // Error state eklendi
       const [features, setFeatures] = useState('lsc;fs;dt'); // Varsayılan özellikler
       const [oid, setOid] = useState('ce26bfc9b7674758879206d7013064bf'); // Varsayılan Object ID
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme durumu

    const handleAddSection = () => {
        setAccordionSections([...accordionSections, { title: '', subtitle: '', error: '' }]);
    };

    const handleSectionChange = (index: number, field: string, value: string) => {
        const newSections = accordionSections.map((section, i) =>
            i === index
                ? {
                    ...section,
                    [field]: value,
                    error: field === 'subtitle' && value.length > 200 ? 'Alt başlık 200 karakterden uzun olamaz!' : '',
                }
                : section
        );
        setAccordionSections(newSections);
    };

    const handleRemoveSection = (index: number) => {
        setAccordionSections(accordionSections.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        const componentData = {
            designId: id,
            componentName: 'Accordion 3D Viewer',
            componentData: { features, oid, accordionSections },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-4">
            {/* 3D Features */}
            <div className="mb-4">
                <label className="block text-gray-700">3D Özellikler</label>
                <input
                    type="text"
                    value={features}
                    onChange={(e) => setFeatures(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Özellikleri girin (örn: lsc;fs;dt)"
                />
            </div>

            {/* 3D Object ID */}
            <div className="mb-4">
                <label className="block text-gray-700">3D Object ID</label>
                <input
                    type="text"
                    value={oid}
                    onChange={(e) => setOid(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Object ID girin"
                />
            </div>

            {/* Accordion Sections */}
            {accordionSections.map((section, index) => (
                <div key={index} className="mb-4 p-4 border border-gray-300 rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">Accordion Bölüm {index + 1}</h3>
                        <button onClick={() => handleRemoveSection(index)} className="text-red-500">
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Başlık</label>
                        <input
                            type="text"
                            value={section.title}
                            onChange={(e) => handleSectionChange(index, 'title', e.target.value)}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Başlık"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Alt Başlık</label>
                        <textarea
                            value={section.subtitle}
                            onChange={(e) => handleSectionChange(index, 'subtitle', e.target.value)}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Alt Başlık"
                        />
                    </div>
                </div>
            ))}

            <button onClick={handleAddSection} className="text-blue-500 mb-4">
                Accordion Bölüm Ekle
            </button>

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2 bg-blue-500 text-white rounded-lg">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2 bg-gray-300 rounded-lg">
                    Kaydet
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                >
                    <div
                        className="bg-white bg-opacity-90 rounded-lg p-6 w-full h-full overflow-auto relative"
                        onClick={(e) => e.stopPropagation()} // Modalın içeriğine tıklamayı engelle
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-8 text-gray-500 text-3xl opacity-50 hover:opacity-100 transition-opacity"
                        >
                            &times;
                        </button>
                        {/* AccordionRightCardSection bileşeni ile önizleme yapıyoruz */}
                        <Accordion3DViewerSection
                            componentData={{ features, oid, accordionSections }}
                        />
                    </div>
                </div>
            )}

            
    
        </div>
    );
};

export default Accordion3DViewerForm;
